import store from "@/store";
import { bus } from "@/main";
import draggable from "vuedraggable";
import { Debounce } from "@/utils/vue-helpers";
import { Action, Getter, Mutation } from "vuex-class";
import { WorkStatus } from "@/store/modules/activity.store";
import { getActivityCommission } from "@/utils/model-helpers";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { IndexActivityValuesPayload, IndexActivityPayload, ActivityValue } from "@/store/modules/activity.store";
import { HasActionFilters } from "./activities/has-action-filters";

export type KanbanType = "sales-pipeline" | "dossiers" | "";
export type DisplayMode = "resolved" | "unresolved" | "list";
export type DisplayType = "compact" | "full";

interface DealFilter {
  search: string;
  zipcodes: string[];
  types: string[];
  users: number[];
  range: {
    from: string;
    to: string;
    subject?: string;
  };
}

@Component({
  components: {
    draggable,
    ActivityTransitionFailedModal: () => import("@/components/modals/activity-transition-failed-modal.vue"),
    ActivityLostReasonModal: () => import("@/components/modals/activity-lost-reason.vue"),
  },
})
export class HasKanban extends Mixins(HasActionFilters) {
  @Getter("auth/me") me!: CurrentAccount;
  @Getter("activity/values") activityValues!: ActivityValue[];
  @Getter("activity/all") activities!: Activity[];

  @Mutation("activity/RESET") resetActivity!: () => void;

  @Action("activity/index") indexActivities!: (payload: IndexActivityPayload) => Activity[];
  @Action("activity/values") indexActivityValues!: (payload?: IndexActivityValuesPayload) => ActivityValue[];
  @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Activity;

  filter: DealFilter = {
    search: "",
    zipcodes: [],
    types: [],
    users: [],
    range: {
      from: "",
      to: "",
      subject: "",
    },
  };

  displayMode: DisplayMode = "unresolved";

  displayType: DisplayType = "full";

  kanbanType: KanbanType = "";

  initialWorkStatuses: WorkStatus[] = [];

  lazyLoadWorkStatuses: WorkStatus[] = [];

  /**
   * This query will be used to load the initial and filtered kanban data.
   *
   */
  indexQuery: any = {
    per_page: 999,
    q: {
      and: {
        and: {
          and: {
            and: {
              and: {},
              or: {},
            },
            or: {},
          },
          or: {},
        },
        or: {
          work_status: [],
        },
      },
    },
  };

  get users() {
    if (!this.activityValues) {
      return;
    }

    const activityValue = this.activityValues.find((av) => av.field === "assigned_to.id");
    return activityValue?.results.map((r) => {
      return { ...r, value: r.term, id: r.term };
    });
  }

  get propertyTypes() {
    if (!this.activityValues) {
      return;
    }

    const activityValue = this.activityValues.find((av) => av.field === "property_type");
    return activityValue?.results.map((r) => {
      return { label: this.$t(`property.types.${r.term}`), value: r.term };
    });
  }

  get zipcodes() {
    if (!this.activityValues) {
      return;
    }

    const activityValue = this.activityValues.find((av) => av.field === "property_address.zip");
    return activityValue?.results.map((r) => {
      return { label: r.term, value: r.term };
    });
  }

  get columnClass() {
    return {
      "h-full w-[calc(100%/1.4)] w-min-w-[calc(100%/1.4)] md:w-1/2 md:min-w-1/2 lg:w-1/3 lg:min-w-1/3": this.displayMode === "resolved",
      "w-[calc(100%/1.4)] sm:w-[calc(100%/2.2)] md:w-[calc(100%/2.8)] lg:w-[calc(100%/3.2)] xl:w-[calc(100%/4.2)] 2xl:w-[calc(100%/6)]": this.displayMode === "unresolved",
    };
  }

  /**
   * @name Get local activities
   * @description Get the activities that are filtered by the selected actions, this filtering is done after the intial api filtering.
   *
   * @returns {Activity[]}
   */
  get localActivities() {
    if (!this.selectedActions || this.selectedActions.length === 0) {
      return this.activities;
    }

    return this.getActivitiesFilteredByAction(this.activities);
  }

  get estimageBudget() {
    return this.localActivities
      .filter((a) => a.work_status === WorkStatus.estimate)
      .reduce((accumulator, activity) => {
        return accumulator + getActivityCommission(activity).commission;
      }, 0);
  }

  get followUpBudget() {
    return this.localActivities
      .filter((a) => a.work_status === WorkStatus.followUp)
      .reduce((accumulator, activity) => {
        return accumulator + getActivityCommission(activity).commission;
      }, 0);
  }

  get followUpLTBudget() {
    return this.localActivities
      .filter((a) => a.work_status === WorkStatus.followUpLT)
      .reduce((accumulator, activity) => {
        return accumulator + getActivityCommission(activity).commission;
      }, 0);
  }

  async mounted() {
    // Get mode from current route
    this.displayMode = this.$route.hash && ["#resolved", "#unresolved", "#list"].includes(this.$route.hash) ? (this.$route.hash.replace("#", "") as DisplayMode) : "unresolved";

    this.resetActivity();

    this.indexQuery.q.and.or.work_status = this.initialWorkStatuses;

    await this.indexActivityValues();

    this.setCachedFilters();
  }

  setCachedFilters() {
    const queryDealFilter: any = this.$route.query;

    if (queryDealFilter.users) {
      this.filter.users = ([...new Set(queryDealFilter.users.split(","))] as string[]).map((u: string) => Number(u));
    } else {
      this.filter.users.push(Number(this.me.id));
    }

    if (queryDealFilter.zipcodes) {
      this.filter.zipcodes = [...new Set(queryDealFilter.zipcodes.split(","))] as string[];
    }

    if (queryDealFilter.types) {
      this.filter.types = [...new Set(queryDealFilter.types.split(","))] as string[];
    }

    if (queryDealFilter.from && queryDealFilter.to) {
      this.filter.range.from = queryDealFilter.from;
      this.filter.range.to = queryDealFilter.to;
      if (queryDealFilter.subject) {
        this.filter.range.subject = queryDealFilter.subject;
      }
    }

    if (queryDealFilter.search) {
      this.filter.search = queryDealFilter.search;
    }
  }

  async moveEnded(event: { item: { _underlying_vm_: { id: number } }; to: HTMLElement; from: HTMLElement }) {
    const eventId = event.item._underlying_vm_.id;
    const fromColumn = event.from.dataset.column;
    const toColumn = event.to.dataset.column;

    document.querySelectorAll(".column.on-hover").forEach((column) => {
      column.classList.remove("on-hover");
    });

    if (fromColumn === toColumn) {
      return;
    }

    let activity = this.localActivities.find((activity: Activity) => activity.id === eventId);
    if (!activity) {
      return;
    }

    try {
      store.commit("activity/UPDATE_MODEL", { ...activity, work_status: toColumn, saving: true });

      await this.patchActivity({ id: activity.id, work_status: toColumn as WorkStatus });
    } catch (e: any) {
      if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "transition_exception") {
        bus.$emit("show-transition-failed", { status: e.errors[0].params, activityId: activity.id });
      }
      if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "validation.can_transition") {
        bus.$emit("show-transition-failed", { status: [e.errors[0].message], activityId: activity.id });
      }
      if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "validation.required_if") {
        bus.$emit("show-activity-lost-reason", activity.id);
      }

      store.commit("activity/UPDATE_MODEL", { ...activity, work_status: fromColumn, saving: false });
    }
  }

  createPayloadFromFilter(filter: DealFilter, payload: IndexQuery) {
    const queryObject: any = {};

    if (filter.users.length) {
      //@ts-ignore
      (payload!.q!.and!.and!.or ??= { not: { "assigned_to.id": "exists: " } })["assigned_to.id"] ??= filter.users;
      queryObject.users = filter.users.join(",");
    }

    if (filter.search !== "") {
      //@ts-ignore
      (payload!.q!.and!.and!.and!.or ??= {}).name ??= [`like:*${filter.search}*`, `match:${filter.search}`];
      queryObject.search = filter.search;
    }

    if (filter.types.length) {
      //@ts-ignore
      (payload!.q!.and!.and!.and!.and!.or ??= {}).property_type ??= filter.types;
      queryObject.types = filter.types.join(",");
    }

    if (filter.zipcodes.length) {
      //@ts-ignore
      (payload!.q!.and!.and!.and!.and!.and!.or ??= {})["property_address.zip"] ??= filter.zipcodes;
      queryObject.zipcodes = filter.zipcodes.join(",");
    }

    if (filter.range.from && filter.range.to) {
      if (this.kanbanType === "sales-pipeline") {
        let subject = "created_at";
        if (filter.range.subject && ["created_at", "dossier_started_at"].includes(filter.range.subject)) {
          subject = filter.range.subject;
        }
        //@ts-ignore
        (payload!.q!.and!.and!.and!.and!.and ??= {})[subject] ??= [`gte:${filter.range.from}`, `lte:${filter.range.to}`];
        queryObject.from = filter.range.from;
        queryObject.to = filter.range.to;
        queryObject.subject = filter.range.subject;
      } else if (this.kanbanType === "dossiers") {
        let subject = "dossier_sold_at";
        if (
          filter.range.subject &&
          ["dossier_started_at", "dossier_startup_at", "dossier_sold_at", "dossier_online_at", "dossier_option_at", "dossier_out_bo_at"].includes(filter.range.subject)
        ) {
          subject = filter.range.subject;
        }
        //@ts-ignore
        (payload!.q!.and!.and!.and!.and!.and ??= {})[subject] ??= [`gte:${filter.range.from}`, `lte:${filter.range.to}`];
        queryObject.from = filter.range.from;
        queryObject.to = filter.range.to;
        queryObject.subject = filter.range.subject;
      }
    }

    return queryObject;
  }

  checkMove(event: any) {
    const fromColumn = event.from.dataset.column;
    const toColumn = event.to.dataset.column;

    document.querySelectorAll(".column.on-hover").forEach((column) => {
      column.classList.remove("on-hover");
    });

    event.to.classList.add("on-hover");

    if (fromColumn === toColumn) {
      return false;
    }

    return true;
  }

  @Watch("filter", { deep: true })
  handleFilterChanged(newValue: DealFilter) {
    let payload: IndexQuery = JSON.parse(JSON.stringify(this.indexQuery));
    const queryObject: any = this.createPayloadFromFilter(newValue, payload);

    if (Object.values(queryObject).length) {
      this.$router.replace({ query: queryObject, hash: this.$route.hash ?? this.displayMode }).catch((err) => {
        if (err.name != "NavigationDuplicated") {
        }
      });
    }

    this.searchActivities(payload);

    if (this.lazyLoadWorkStatuses.length > 0) {
      let subPayload: IndexQuery = {
        per_page: 999,
        q: {
          and: {
            and: payload.q?.and?.and,
            or: {
              // @ts-ignore
              work_status: this.lazyLoadWorkStatuses,
            },
          },
        },
      };

      this.searchAdditionalActivities(subPayload);
    }
  }

  @Debounce(500)
  async searchActivities(payload: any) {
    const activities = await this.indexActivities(payload);

    const activityIds = activities.map((activity: Activity) => activity.id);
  }

  @Debounce(500)
  async searchAdditionalActivities(payload: any) {
    const activities = await this.indexActivities({ ...payload, merge: true });

    const activityIds = activities.map((activity: Activity) => activity.id);
  }
}
