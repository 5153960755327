import { Action, Getter, Mutation } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { MyWorkActivityActionLabelTypes } from "@/enums/my-work";
import { ActivityActionLabel, getActionLabelBasedOnActivity } from "@/utils/activities/action-label-helpers";

@Component
export class HasActionFilters extends Vue {
  selectedActions: ActivityActionLabel[] = [];

  actions = [
    { value: ActivityActionLabel.Due, label: "Deadline verstreken", color: "red" },
    { value: ActivityActionLabel.Unscheduled, label: "Geen actie gepland", color: "yellow" },
    { value: ActivityActionLabel.Today, label: "Vandaag", color: "green" },
    { value: ActivityActionLabel.Scheduled, label: "Actie gepland", color: "gray-300" },
  ];

  getActivitiesFilteredByAction(activities: Activity[]) {
    return activities.filter((activity) => {
      const actionLabel = getActionLabelBasedOnActivity(activity);

      return Object.values(this.selectedActions).includes(actionLabel.value);
    });
  }
}
