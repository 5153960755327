import { parse } from "fecha";

enum ActivityActionLabel {
  Due = "DUE",
  Today = "TODAY",
  Unscheduled = "UNSCHEDULED",
  Scheduled = "SCHEDULED",
}

function getActionLabelBasedOnActivity(activity: Activity) {
  // If there are no actions we can assume the activity is unscheduled
  if (activity.actions.length === 0) {
    return { value: ActivityActionLabel.Unscheduled, action: { title: "", deadline: "", type: "" } };
  }

  // if there is either a task or an an appointment due we will consider the activity as today
  let dT = null;
  if ((dT = activity.actions.find((a) => a.type === "task" && a.deadline && a.deadline && parse(a.deadline, "isoDateTime")! < new Date()))) {
    return { value: ActivityActionLabel.Due, action: dT, type: "task" };
  }

  // If there is either a task or an an appointment today we will consider the activity as today
  let tdT = null;
  if ((tdT = activity.actions.find((a) => a.type === "task" && a.deadline && new Date(a!.deadline).toDateString() === new Date().toDateString()))) {
    return { value: ActivityActionLabel.Today, action: tdT, type: "task" };
  }

  let tdA = null;
  if ((tdA = activity.actions.find((a) => a.type === "appointment" && a.deadline && new Date(a!.deadline).toDateString() === new Date().toDateString()))) {
    return { value: ActivityActionLabel.Today, action: tdA, type: "appointment" };
  }

  // if there is either a task in the future or an appointment in the future
  // we will consider the activity as scheduled
  let t = null;
  if ((t = activity.actions.find((a) => a.type === "task" && a.deadline && parse(a.deadline, "isoDateTime")! > new Date()))) {
    return { value: ActivityActionLabel.Scheduled, action: t, type: "task" };
  }

  let a = null;
  if ((a = activity.actions.find((a) => a.type === "appointment" && a.deadline && parse(a.deadline, "isoDateTime")! > new Date()))) {
    return { value: ActivityActionLabel.Scheduled, action: a, type: "appointment" };
  }

  return { value: ActivityActionLabel.Unscheduled, action: { title: "", deadline: "", type: "" } };
}

export { ActivityActionLabel, getActionLabelBasedOnActivity };
